<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="this.api"/>
        <v-row class="mt-3">
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <v-toolbar class="secondary my-4">
                        <v-toolbar-title class="white--text text-h4">
                            AAE Minutes
                        </v-toolbar-title>
                    </v-toolbar>
                    <!--BOC : Create a form here-->
                    <div class="mx-3">
                        <div >
    
                            <v-text-field 
                            label="Company Name"
                            dense outlined
                            filled disabled v-model="companyName">
        
                            </v-text-field>
                        </div>
                        <div>
                            <v-text-field 
                            label="Current Minutes"
                            dense outlined
                            filled disabled v-model="currentMinutes">
    
                            </v-text-field>
    
                        </div>
                        <div>
                            <v-text-field dense outlined
                            :rules="rules.min" 
                            min="0"
                            max="100"
                            v-model="additionalMinutes" 
                            type="number"
                            suffix="minutes"
                            placeholder="Add minutes...">
    
                            </v-text-field>
                        </div>

                        <div class="d-flex flex-row">
                            <v-textarea filled solo dense v-model="remark" label="Remark...">

                            </v-textarea>
                        </div>
                        <div class="d-flex justify-end">
                            <v-btn 
                            @click="redirectBack" color="primary" plain>
                                Cancel
                            </v-btn>
                            <v-btn 
                            @click="validateInput"
                            :loading="api.isLoading"
                            class="primary" >
                                Confirm
                            </v-btn>
                        </div>

                    </div>


                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    ASuccessFour,
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        rules:{
            min:v=>v>0 || 'Min 1 minute'

        },
        isPending:false,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:null,
        },
        remark:null,
        companyName:null,
        currentMinutes:null,
        additionalMinutes:null,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getCompany"){
                this.companyName=resp.data.name;
                this.currentMinutes = resp.data.minutes

            }
            if(resp.class=="updateAAEMinutes"){

                this.api.isSuccesful = true;
                this.api.success="Succesfully Add Minutes";
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchCompaniesCurrentMinutesApi = this.fetchCompaniesCurrentMinutes();
            this.$api.fetch(fetchCompaniesCurrentMinutesApi); 
        },
        fetchCompaniesCurrentMinutes(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/companies/"+this.$router.history.current.params.id;
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;

            let createAAEApi = this.create();
            this.$api.fetch(createAAEApi);
        },
        cancelSubmit(){
            this.isPending =false;
        },
        create(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/company/update/AAE/minutes"
            let formData = new FormData;
            formData.append('id',this.$router.history.current.params.id);
            formData.append('minutes',this.additionalMinutes);
            formData.append('user_id',this.$store.getters.getUserId);
            formData.append('remark',this.remark);
            
            tempApi.params = formData;
            return tempApi;
        },
        redirectBack(){
            this.$router.go(-1);
        }
    }
}
</script>